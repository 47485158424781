import { graphql } from 'gatsby';
import TeachersPage from './page';

export default TeachersPage;

export const query = graphql`
  query TeacherPageTemplateQuery($slug: String!) {
    page: sanityTeacherPage(slug: {current: {eq: $slug}}) {
      slug {
        current
      }
      title
      featureImage {
        ...Image
        asset {
          title
          gatsbyImageData(width: 1980, placeholder: NONE, layout: FULL_WIDTH)
        }
      }
      _rawContent(resolveReferences: {maxDepth: 10})
      information {
        _key
        title
        _rawContent(resolveReferences: {maxDepth: 10})
        open
      }
    }
  }
`
